<template>
  <v-layout>
    <v-col class="custom-content-container pt-0" cols="12" md="12" sm="12">
      <!-- <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll"
        style="max-height: 60vh; position: relative"
      > -->
      <v-layout class="d-block">
        <v-row>
          <v-col class="iv-custom-field pt-0" cols="12" md="12" sm="12">
            <v-container fluid>
              <template v-for="(pdata, index) in contactPersons">
                <v-row :key="'contact-person-' + index">
                  <v-col :md="isDialog ? 4 : 1" sm="12">
                    <v-select
                      v-model.trim="pdata.title"
                      :items="salutationList"
                      dense
                      filled
                      :rules="[
                        validateRules.required(pdata.title, 'salutation')
                      ]"
                      item-text="text"
                      item-value="value"
                      item-color="cyan"
                      label="Salutation"
                      solo
                      flat
                      color="cyan"
                    ></v-select>
                  </v-col>
                  <v-col :md="isDialog ? 4 : 2" sm="12">
                    <v-text-field
                      v-model.trim="pdata.first_name"
                      :rules="[
                        validateRules.required(pdata.first_name, 'first name'),
                        validateRules.minLength(
                          pdata.first_name,
                          'first name',
                          2
                        ),
                        validateRules.maxLength(
                          pdata.first_name,
                          'first name',
                          100
                        )
                      ]"
                      dense
                      filled
                      label="First Name"
                      solo
                      flat
                      color="cyan"
                      class="required-field"
                    ></v-text-field>
                  </v-col>
                  <v-col :md="isDialog ? 4 : 2" sm="12">
                    <v-text-field
                      v-model.trim="pdata.last_name"
                      :rules="[
                        validateRules.minLength(
                          pdata.last_name,
                          'last name',
                          2
                        ),
                        validateRules.maxLength(
                          pdata.last_name,
                          'last name',
                          100
                        )
                      ]"
                      dense
                      filled
                      label="Last Name"
                      solo
                      flat
                      color="cyan"
                    ></v-text-field>
                  </v-col>
                  <v-col :md="isDialog ? 4 : 2" sm="12">
                    <v-text-field
                      v-model.trim="pdata.display_name"
                      :rules="[
                        validateRules.required(
                          pdata.display_name,
                          'display name'
                        ),
                        validateRules.minLength(
                          pdata.display_name,
                          'display name',
                          2
                        ),
                        validateRules.maxLength(
                          pdata.display_name,
                          'display name',
                          100
                        )
                      ]"
                      dense
                      filled
                      label="Display Name"
                      solo
                      flat
                      color="cyan"
                      class="required-field"
                    ></v-text-field>
                  </v-col>
                  <v-col :md="isDialog ? 4 : 2" sm="12">
                    <v-text-field
                      v-mask="currentPhoneMask"
                      v-model.trim="pdata.primary_phone"
                      :rules="[
                        validateRules.required(pdata.primary_phone, 'mobile'),
                        validateRules.minLength(
                          pdata.primary_phone,
                          'mobile',
                          6
                        ),
                        validateRules.maxLength(
                          pdata.primary_phone,
                          'mobile',
                          15
                        )
                      ]"
                      dense
                      filled
                      label="Mobile"
                      solo
                      flat
                      color="cyan"
                      class="required-field"
                    ></v-text-field>
                  </v-col>
                  <v-col :md="isDialog ? 4 : 2" sm="12">
                    <v-text-field
                      v-model.trim="pdata.primary_email"
                      :rules="[
                        validateRules.required(pdata.primary_email, 'email'),
                        validateRules.validEmail(pdata.primary_email, 'email'),
                        validateRules.minLength(
                          pdata.primary_email,
                          'email',
                          2
                        ),
                        validateRules.maxLength(
                          pdata.primary_email,
                          'email',
                          100
                        )
                      ]"
                      dense
                      filled
                      label="Email"
                      solo
                      flat
                      color="cyan"
                      class="required-field"
                    ></v-text-field>
                  </v-col>
                  <template v-if="!isDialog">
                    <v-flex md="1" sm="12">
                      <template v-if="index > 0">
                        <v-btn
                          class="mx-2"
                          color="red lighten-1"
                          dark
                          fab
                          small
                          v-on:click="removeContactPerson(index)"
                        >
                          <v-icon dark> mdi-minus</v-icon>
                        </v-btn>
                      </template>
                      <template v-else>
                        <v-btn
                          class="mx-2"
                          color="cyan"
                          dark
                          fab
                          small
                          v-on:click="pushContactPerson"
                        >
                          <v-icon dark> mdi-plus</v-icon>
                        </v-btn>
                      </template>
                    </v-flex>
                  </template>
                </v-row>
              </template>
            </v-container>
          </v-col>
        </v-row>
      </v-layout>
      <!-- </perfect-scrollbar> -->
    </v-col>
  </v-layout>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import SupplierMixin from "@/core/plugins/supplier-mixin";

export default {
  name: "contact-persons",
  title: "Supplier",
  mixins: [CommonMixin, ValidationMixin, SupplierMixin],
  props: {
    detail: {
      type: Object,
      default: function() {
        return {};
      }
    },
    supplier: {
      type: Object,
      default: function() {
        return {};
      }
    },
    isDialog: {
      type: Boolean,
      default: false
    },
    person: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      contactPersons: [
        {
          id: null,
          title: "mr",
          first_name: null,
          last_name: null,
          display_name: null,
          primary_email: null,
          primary_phone: null,
          will_notified: 1,
          position: null,
          default: 0
        }
      ]
    };
  },
  watch: {
    contactPersons: {
      deep: true,
      immediate: true,
      handler(param) {
        let result = this.lodash.cloneDeep(param);
        this.$emit("saveContactPerson", result);
      }
    },
    detail: {
      deep: true,
      handler() {
        this.makeDefaultEntry();
      }
    }
  },
  methods: {
    makeDefaultEntry() {
      const _this = this;
      try {
        let emailData = _this.lodash.filter(_this.detail.details, function(
          data
        ) {
          return data.type === 1;
        });
        let contactData = _this.lodash.filter(_this.detail.details, function(
          data
        ) {
          return data.type === 2;
        });
        let firstEntry = {
          id: null,
          title: _this.lodash.toString(_this.detail.salutation),
          first_name: _this.lodash.toString(_this.detail.first_name),
          last_name: _this.lodash.toString(_this.detail.last_name),
          display_name: _this.lodash.toString(_this.createDisplayName()),
          primary_email: _this.lodash.head(emailData)
            ? _this.lodash.toString(_this.lodash.head(emailData).value)
            : null,
          primary_phone: _this.lodash.head(contactData)
            ? _this.lodash.toString(_this.lodash.head(contactData).value)
            : null,
          default: 1,
          will_notified: 1,
          position: null
        };
        _this.contactPersons.splice(0, 1, firstEntry);
      } catch (error) {
        _this.contactPersons.splice(0, 1);
        _this.logError(error);
      }
    },
    createDisplayName() {
      let firstname = "";
      if (this.detail.first_name) {
        firstname = this.detail.first_name.toLowerCase();
      }
      let lastname = "";
      if (this.detail.last_name) {
        lastname = this.detail.last_name.toLowerCase();
      }
      return firstname + lastname;
    },
    pushContactPerson() {
      this.contactPersons.push({
        id: null,
        title: "mr",
        first_name: null,
        last_name: null,
        display_name: null,
        primary_email: null,
        primary_phone: null,
        will_notified: 1,
        position: null,
        default: 0
      });
    },
    removeContactPerson(index) {
      this.contactPersons.splice(index, 1);
    }
  },
  mounted() {
    this.makeDefaultEntry();
    if (this.person > 0) {
      this.contactPersons = [this.getPersonById(this.supplier, this.person)];
    }
  }
};
</script>
